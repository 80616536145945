<template>
  <div
    :class="{ dark: section.dark_mode }"
    :style="styledBg"
    class="
      component
      position-relative
      c-contentstream
      padding-y-24
      bp-768:padding-y-48
    "
  >
    <VideoModal
      :selectedvideo="selectedVideo"
      :value="video_modal_toggled"
      v-on:input="close_video_modal(selectedVideo)"
    />
    <div class="row padding-x-12 position-relative z-index-3">
      <common-header :headline="section.headline" :sponsor="section.sponsor" />
    </div>
    <div class="row padding-t-12 padding-b-6 position-relative z-index-3">
      <div
        role="tablist"
        class="
          display-flex
          width-100
          padding-x-12 padding-y-24
          c-contentstream__filters
        "
      >
        <div class="c-common__select width-100 bp-1024:display-none">
          <select v-model="selected_display_type">
            <option
              v-for="type in display_types"
              :key="type.value"
              :value="type"
            >
              {{ type.type }}
            </option>
          </select>
        </div>
        <ul
          id="chipsFeed"
          role="presentation"
          class="
            s-common__filter
            flex-grow-1
            width-100
            reset-list
            display-none
            padding-x-24
            bp-1024:flex-wrap-wrap bp-1024:display-flex
            align-items-center
            justify-content-center
          "
        >
          <li
            role="tab"
            :tabindex="selected_display_type.value == type.value ? 0 : -1"
            :aria-selected="
              selected_display_type.value == type.value ? 'true' : 'false'
            "
            @keydown="onKeydown($event, k)"
            :aria-controls="`tab${k}`"
            class="s-common__filter-button"
            @keydown.enter="selected_display_type = type"
            @keydown.space="selected_display_type = type"
            @click="selected_display_type = type"
            v-for="(type, k) in display_types"
            :key="type.value"
            :class="{ 'is-active': selected_display_type.value === type.value }"
          >
            <feather
              size="24px"
              class="margin-r-12"
              :type="elementIcon(type.value)"
            ></feather>
            <span class="font-size-18 font-weight-700 line-height-125">
              {{ type.type }}
            </span>
          </li>
        </ul>
      </div>
      <template v-if="contentstream_elements.length === 0">
        <div
          class="flex-item-1"
          style="
            padding: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          Please choose another filter.
        </div>
      </template>
      <div
        v-if="contentstream_elements.length != 0"
        class="width-100 padding-t-12 padding-b-6"
      >
        <masonry
          :cols="{ default: 3, 1024: 2, 767: 1 }"
          class="c-contentstream__grid w-100 flex-grow-1"
        >
          <template v-for="(item, index) in contentstream_elements">
            <div
              v-if="
                item.type === 'tweet' ||
                item.type === 'youtube' ||
                item.type === 'allaccess' ||
                item.type === 'facebook' ||
                item.type === 'instagram' ||
                item.type === 'gallery' ||
                item.type === 'story'
              "
              v-bind:class="item.type"
              :key="item.data.id + index"
              class="
                grid-item
                c-contentstream__item-wrapper
                display-flex
                padding-12
                width-100
              "
            >
              <div
                class="c-contentstream__item width-100"
                :class="'c-contentstream__item--' + item.type"
              >
                <!-- gallery -->
                <div
                  v-if="item.type === 'gallery'"
                  class="
                    c-contentstream__item-inner
                    c-contentstream__item-inner--gallery
                  "
                >
                  <div
                    v-if="
                      item.data.images &&
                      item.data.images.large &&
                      item.data.images.large.url
                    "
                    class="c-contentstream__media"
                  >
                    <a :href="item.data.url">
                      <img
                        :src="item.data.images.large.url"
                        :alt="item.data.title + ' ' + item.data.id"
                      />
                    </a>
                  </div>
                  <div
                    class="
                      c-contentstream__body
                      padding-x-24
                      font-weight-300
                      line-height-175
                      margin-b-auto
                      padding-b-12
                      flex-grow-1 flex-shrink-1
                    "
                  >
                    <h3
                      class="c-contentstream__title"
                      v-html="item.data.title"
                    ></h3>
                    <div
                      class="
                        c-contentstream__info
                        display-flex
                        flex-wrap-wrap
                        justify-content-space-between
                        align-items-center
                      "
                    >
                      <a
                        class="
                          s-common__button s-common__button-primary
                          margin-r-24
                          c-stories__card--item-more
                          font-weight-700 font-size-12
                          line-height-125
                        "
                        :href="item.data.url"
                        >View Full Gallery</a
                      >
                      <div
                        class="c-common__pill margin-y-12 c-contentstream__date"
                      >
                        <span>{{
                          item.data.date
                            ? moment(item.data.date).format("MMM DD, YYYY")
                            : "N/A"
                        }}</span
                        ><span> | {{ item.data.sport.title }} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- youtube -->
                <div
                  v-if="item.type === 'youtube'"
                  class="
                    c-contentstream__item-inner
                    c-contentstream__item-inner--youtube
                  "
                >
                  <div
                    v-if="
                      (item.data.maxres && item.data.maxres.url) ||
                      (item.data.medium && item.data.medium.url)
                    "
                    class="c-contentstream__media position-relative"
                  >
                    <img
                      :src="
                        item.data.maxres && item.data.maxres.url
                          ? item.data.maxres.url
                          : item.data.medium.url
                      "
                      :alt="item.data.title + ' ' + item.data.id"
                    />

                    <div
                      v-on:click="play_video(item)"
                      class="c-contentstream__play c-common__play"
                    >
                      <feather size="32px" type="play"></feather>
                    </div>
                  </div>
                  <div
                    class="
                      c-contentstream__body
                      padding-x-24
                      font-weight-300
                      line-height-175
                      margin-b-auto
                      padding-b-24
                      flex-grow-1 flex-shrink-1
                    "
                  >
                    <h3
                      class="c-contentstream__title"
                      v-html="item.data.title"
                    ></h3>
                  </div>
                </div>
                <!-- all access -->
                <div
                  v-if="item.type === 'allaccess'"
                  class="
                    c-contentstream__item-inner
                    c-contentstream__item-inner--allaccess
                  "
                >
                  <div
                    v-if="item.data.poster"
                    class="c-contentstream__media position-relative"
                  >
                    <img
                      :src="item.data.poster"
                      :alt="item.data.title + ' ' + item.data.id"
                    />
                    <div
                      v-on:click="play_video(item)"
                      class="c-contentstream__play c-common__play"
                    >
                      <feather size="32px" type="play"></feather>
                    </div>
                  </div>
                  <div
                    class="
                      c-contentstream__body
                      padding-x-24
                      font-weight-300
                      line-height-175
                      margin-b-auto
                      padding-b-24
                      flex-grow-1 flex-shrink-1
                    "
                  >
                    <h3
                      class="c-contentstream__title"
                      v-html="item.data.title"
                    ></h3>
                  </div>
                </div>
                <!-- story -->
                <div
                  v-if="item.type === 'story'"
                  class="
                    c-contentstream__item-inner
                    c-contentstream__item-inner--story
                  "
                >
                  <div
                    v-if="item.data.image_source"
                    class="c-contentstream__media"
                  >
                    <img
                      :src="item.data.image_source"
                      :alt="item.data.image_alt_text"
                    />
                  </div>
                  <div
                    class="
                      c-contentstream__body
                      padding-x-24
                      font-weight-300
                      line-height-175
                      margin-b-auto
                      padding-b-12
                      flex-grow-1 flex-shrink-1
                    "
                  >
                    <h3
                      class="c-contentstream__title"
                      v-html="item.data.title"
                    ></h3>
                    <div
                      class="c-contentstream__teaser"
                      v-html="item.data.teaser"
                    ></div>
                    <div
                      class="
                        c-contentstream__info
                        display-flex
                        flex-wrap-wrap
                        justify-content-space-between
                        align-items-center
                      "
                    >
                      <a
                        class="
                          s-common__button s-common__button-primary
                          c-stories__card--item-more
                          font-weight-700 font-size-12
                          line-height-125
                        "
                        :href="item.data.url"
                        >Read Full Story</a
                      >
                      <div
                        class="c-contentstream__date margin-y-12 c-common__pill"
                      >
                        <span>{{
                          item.data.date
                            ? moment(item.data.date).format("MMM DD, YYYY")
                            : "N/A"
                        }}</span
                        ><span> | {{ item.data.sport.title }} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- instagram -->
                <div
                  v-if="item.type === 'instagram'"
                  class="
                    c-contentstream__item-inner
                    c-contentstream__item-inner--instagram
                  "
                >
                  <div
                    class="
                      c-contentstream__user c-contentstream__user--instagram
                      display-flex
                      align-items-center
                      padding-24
                      line-height-125
                    "
                  >
                    <span
                      class="
                        d-inline-block
                        c-contentstream__user-icon
                        sf-instagram
                        margin-r-12
                        font-size-24
                      "
                    ></span>
                    <a
                      :href="'http://instagram.com/' + item.data.user.username"
                      class="
                        c-contentstream__user-name
                        c-contentstream__user-name--instagram
                        font-weight-bold font-size-14
                      "
                      >{{ "@" + item.data.user.username }}</a
                    >
                  </div>
                  <div
                    class="
                      c-contentstream__media-container
                      c-contentstream__media-container--instagram
                    "
                  >
                    <a
                      :href="item.data.link"
                      target="_blank"
                      class="c-contentstream__media-link"
                    >
                      <img
                        class="
                          c-contentstream__media
                          c-contentstream__media--instagram
                        "
                        :src="item.data.images.standard_resolution.url"
                        :alt="
                          item.data.caption
                            ? item.data.caption.text
                            : 'No Instagram caption available'
                        "
                      />
                    </a>
                  </div>
                </div>
                <!-- facebook -->
                <div
                  v-if="item.type === 'facebook'"
                  class="
                    c-contentstream__item-inner
                    c-contentstream__item-inner--facebook
                    display-flex
                    flex-direction-column
                    align-items-start
                    height-100
                  "
                >
                  <div
                    v-if="item.data.full_picture"
                    class="c-contentstream__media"
                  >
                    <img
                      :src="item.data.full_picture"
                      :alt="item.data.content_title"
                    />
                  </div>
                  <div
                    class="
                      c-contentstream__user c-contentstream__user--twitter
                      display-flex
                      align-items-center
                      padding-24
                      line-height-125
                      flex-shrink-0
                    "
                  >
                    <span
                      class="
                        d-inline-block
                        c-contentstream__user-icon
                        sf-facebook
                        margin-r-12
                        font-size-24
                      "
                    ></span>
                    <a
                      :href="item.data.link_to_post"
                      class="
                        c-contentstream__user-name
                        c-contentstream__user-name--twitter
                        font-weight-bold font-size-14
                      "
                      >@{{ item.data.page.username }}</a
                    >
                  </div>
                  <div
                    class="
                      c-contentstream__body
                      padding-x-24
                      font-weight-300
                      line-height-175
                      margin-b-auto
                      padding-b-24
                      flex-grow-1 flex-shrink-1
                    "
                    v-html="item.data.message"
                  ></div>
                  <div
                    class="
                      c-contentstream__info
                      padding-t-0 padding-l-24 padding-r-24 padding-b-12
                      display-flex
                      flex-wrap-wrap
                      justify-content-space-between
                      align-items-center
                    "
                  >
                    <a
                      class="
                        s-common__button s-common__button-primary
                        c-stories__card--item-more
                        font-weight-700 font-size-12
                        line-height-125
                      "
                      :href="item.data.link_to_post"
                      >Link To Post</a
                    >
                    <div
                      class="c-contentstream__date margin-y-12 c-common__pill"
                    >
                      <span>{{
                        item.data.created_time
                          ? moment(item.data.created_time).format(
                              "MMM DD, YYYY"
                            )
                          : null
                      }}</span>
                    </div>
                  </div>
                </div>
                <!-- twitter -->
                <div
                  v-if="item.type === 'tweet'"
                  class="
                    c-contentstream__item-inner
                    c-contentstream__item-inner--twitter
                    display-flex
                    flex-direction-column
                    align-items-start
                    height-100
                  "
                >
                  <div v-if="item.data.media" class="c-contentstream__media">
                    <img
                      :src="item.data.media"
                      :alt="item.data.image_alt_text"
                    />
                  </div>
                  <div
                    class="
                      c-contentstream__user c-contentstream__user--twitter
                      display-flex
                      align-items-center
                      padding-24
                      line-height-125
                      flex-shrink-0
                    "
                  >
                    <span
                      class="
                        d-inline-block
                        c-contentstream__user-icon
                        sf-twitter
                        margin-r-12
                        font-size-24
                      "
                    ></span>
                    <a
                      :href="'http://twitter.com/' + item.data.user.screen_name"
                      class="
                        c-contentstream__user-name
                        c-contentstream__user-name--twitter
                        font-weight-bold font-size-14
                      "
                      >@{{ item.data.user.screen_name }}</a
                    >
                  </div>
                  <div
                    class="
                      c-contentstream__body
                      padding-x-24
                      font-weight-300
                      line-height-175
                      margin-b-auto
                      padding-b-24
                      flex-grow-1 flex-shrink-1
                    "
                    v-html="item.data.text"
                  ></div>
                  <div
                    class="
                      c-contentstream__footer
                      padding-y-16
                      width-100
                      flex-shrink-0
                    "
                  >
                    <ul
                      class="
                        c-contentstream__actions
                        reset-list
                        w-100
                        display-flex
                        align-items-center
                        justify-content-center
                        font-size-24
                        line-height-1
                      "
                    >
                      <li class="c-contentstream__actions-item margin-x-12">
                        <a
                          class="c-contentstream__actions-link"
                          :href="
                            'https://twitter.com/intent/favorite?tweet_id=' +
                            item.data.id
                          "
                          aria-label="Favorite this tweet"
                          target="_blank"
                        >
                          <span
                            aria-hidden="true"
                            class="sf-twitter-like sidearm-icon"
                          ></span>
                        </a>
                      </li>
                      <li class="c-contentstream__actions-item margin-x-12">
                        <a
                          class="c-contentstream__actions-link"
                          :href="
                            'https://twitter.com/intent/retweet?tweet_id=' +
                            item.data.id
                          "
                          aria-label="Retweet this tweet"
                          target="_blank"
                        >
                          <span
                            aria-hidden="true"
                            class="sf-twitter-retweet sidearm-icon"
                          ></span>
                        </a>
                      </li>
                      <li class="c-contentstream__actions-item margin-x-12">
                        <a
                          class="c-contentstream__actions-link"
                          :href="
                            'https://twitter.com/intent/tweet?in_reply_to=' +
                            item.data.id
                          "
                          aria-label="Reply to this tweet"
                          target="_blank"
                        >
                          <span
                            aria-hidden="true"
                            class="sf-twitter-reply sidearm-icon"
                          ></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </masonry>
        <template v-if="is_loading_more">
          <div
            class="flex-item-1"
            style="
              padding: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <CommonLoading></CommonLoading>
          </div>
        </template>
      </div>
    </div>
    <div class="row padding-t-12 position-relative z-index-3">
      <button
        v-if="
          contentstream_elements &&
          contentstream_elements.length > 0 &&
          !hide_load_more
        "
        class="
          s-common__button s-common__button-large
          margin-l-auto margin-r-auto
          font-size-14
          line-height-125
          font-weight-700
          margin-t-12
        "
        @click="load_more"
      >
        Show More
      </button>
    </div>
  </div>
</template>

<script>
import SectionBase from "./SectionBase";
import Client from "@/client";
import CommonLoading from "@/components/common/CommonLoading";
import CommonHeader from "@/components/common/CommonHeader";
import VideoModal from "@/components/modal/VideoModal.vue";
import Vue from "vue";
import VueMasonry from "vue-masonry-css";

Vue.use(VueMasonry);
export default {
  extends: SectionBase,
  name: "SectionContentstream",
  components: { CommonHeader, VideoModal, CommonLoading },
  data: () => ({
    is_loading_more: false,
    contentstream_elements: [],
    display_types: [],
    video_modal_toggled: false,
    selectedVideo: null,
    selected_display_type: { type: "All", value: "all" },
    count: 6,
  }),
  computed: {
    styledBg: function () {
      if (this.section.backgroundImage) {
        return {
          "background-image": "url(" + this.section.backgroundImage + ") ",
        };
      } else if (this.section.useCustomBackgroundColor) {
        return { backgroundColor: this.section.custom_background_color };
      }
      return {};
    },
  },
  methods: {
    setSelectedTab(index) {
      this.selectedTab = index;
    },
    onKeydown(event, index) {
      const tabs = Array.from(
        document.getElementById("chipsFeed").querySelectorAll("[role=tab]")
      );
      switch (event.key) {
        case "ArrowLeft":
          this.moveFocusToTab(index > 0 ? index - 1 : tabs.length - 1);
          event.preventDefault();
          break;
        case "ArrowRight":
          this.moveFocusToTab((index + 1) % tabs.length);
          event.preventDefault();
          break;
        case "Home":
          this.moveFocusToTab(0);
          event.preventDefault();
          break;
        case "End":
          this.moveFocusToTab(tabs.length - 1);
          event.preventDefault();
          break;
        default:
          break;
      }
    },
    moveFocusToTab(index) {
      const tabs = Array.from(
        document.getElementById("chipsFeed").querySelectorAll("[role=tab]")
      );
      this.$nextTick(() => {
        const button = tabs[index];
        if (button) {
          button.focus();
        }
      });
    },
    elementIcon: function (icon) {
      if (icon === "all") return "grid";
      else if (icon === "tweet") return "twitter";
      else if (icon === "instagram") return "instagram";
      else if (icon === "facebook") return "facebook";
      else if (icon === "youtube") return "youtube";
      else if (icon === "allaccess") return "video";
      else if (icon === "gallery") return "camera";
      else if (icon === "story") return "file-text";
      else return null;
    },
    changeFilter(type) {
      this.selected_display_type = type;
      this.is_loading_more = true;
      this.contentstream_elements = [];
      this.get_content_stream();
    },
    play_video(video) {
      this.selectedVideo = video;
      this.video_modal_toggled = true;
    },
    close_video_modal(video) {
      this.video_modal_toggled = false;
      if (video.component_type === "All Access") this.selectedVideo = null;
    },
    get_content_stream() {
      Client.get("/services/adaptive_components.ashx", {
        params: {
          type: "content-stream",
          start: this.contentstream_elements.length,
          count: this.count,
          name:
            (this.selected_display_type && this.selected_display_type.value) ||
            "all",
          sport_id: this.$root.gameday.sport.id,
          mode: "gameday",
          gameday_id: this.$root.gameday.id,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
        },
      }).then((response) => {
        if (response.data) {
          this.contentstream_elements = this.contentstream_elements.concat(
            response.data.stream
          );
          if (response.data.stream && response.data.stream.length) {
            this.hide_load_more = false;
          } else {
            this.hide_load_more = true;
          }
          if (this.display_types.length == 0) {
            this.display_types = response.data.types;
          }
        }

        this.is_loading_more = false;
      });
    },
    filter_stream(display_type) {
      this.contentstream_elements = [];
      this.selected_display_type = display_type;
    },
    load_more() {
      this.is_loading_more = true;
      this.get_content_stream();
    },
  },
  watch: {
    selected_display_type: function (type) {
      this.changeFilter(type);
    },
  },
  mounted() {},
  created() {
    this.get_content_stream();
  },
};
</script>

<style scoped lang="scss">
.c-contentstream {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: $main-color;
    opacity: 0.7;
    z-index: 2;
  }
  &__grid {
    column-count: 3;
  }
  &__date {
  }
  &__info {
    padding-top: 24px;
  }
  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -42px;
    margin-left: -42px;
    z-index: 2;
    transition: 0.25s;
    transform: rotate(0deg);
    i {
      display: block;
      transform: rotate(0deg);
      transition: 0.25s;
    }
  }
  &__item {
    background: $main-color;
    border: solid 1px $main-border-color;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    width: 100%;
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
    page-break-inside: avoid;
    &--instagram {
      &:hover {
        .c-contentstream__media-container--instagram {
          &::before {
            opacity: 0.5;
          }
        }
      }
    }
  }
  &__item-inner {
    &--instagram {
    }
  }

  &__body {
    overflow: auto;
    color: $main-font-color;
  }

  &__button {
    text-decoration: none;
    background: $color-primary;
    color: $text-on-primary;
    text-transform: uppercase;
    transition: 0.3s;
    &:hover,
    &:focus {
      background: $color-secondary;
      color: $text-on-secondary;
    }
  }

  &__footer {
    border-top: solid 1px $main-border-color;
  }
  &__media {
    img {
      width: 100%;
    }
  }
  &__media-container--instagram {
    padding: 24px;
    padding-top: 0;
    position: relative;
    overflow: hidden;
  }
  &__media-link {
  }
  &__media--instagram {
  }

  &__user {
    color: $main-font-color;
  }

  &__user--instagram {
    width: 100%;
    z-index: 3;
    color: $main-font-color;
    pointer-events: none;
  }
  &__user-name {
    text-decoration: none;
    color: $main-font-color;
    pointer-events: all;
    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &--instagram {
      color: $main-font-color;
    }
  }

  @media screen and (max-width: 767px) {
    &__item {
      padding-bottom: 0;
    }
    &__item-inner {
      height: auto;
      position: relative;
    }
    &__media-container--instagram {
      padding-bottom: 0;
    }
    &__media-link {
      position: relative;
    }
    &__media--instagram {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
    }
  }
}
</style>

<style lang="scss">
.c-contentstream__play {
  transition: 0.25s;
  transform: rotate(0deg);
  i {
    display: block;
    transform: rotate(0deg);
    transition: 0.25s;
  }
  &:hover {
    transform: rotate(90deg);
    i {
      transition: 0.25s;
      transform: rotate(-90deg);
      transition: 0.25s;
      fill: #fff;
    }
    transition: 0.25s;
    background: $color-primary;
    color: $text-on-primary;
    border: 2px dashed $color-primary;
  }
}
</style>
